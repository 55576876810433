export enum EAuthenticationStep {
  Email = 'Email',
  Password = 'Password',
  RedirectOEI = 'RedirectOEI',
  ResetPassword = 'ResetPassword',
  MultiFactorAuthentication = 'MultiFactorAuthentication',
  Success = 'Success',
  Failure = 'Failure',
  ResetPasswordSuccess = 'ResetPasswordSuccess',
  MigrationOEI = 'MigrationOEI',
  MigrationOEISuccess = 'MigrationOEISuccess',
  MigrationOEIEmailVerification = 'MigrationOEIEmailVerification',
  MigrationOEIEOLAcount = 'MigrationOEIEOLAcount',
  MigrationOEIEOLAcountExisting = 'MigrationOEIEOLAcountExisting',
  MigrationOEIEOLAcountExistingConfirm = 'MigrationOEIEOLAcountExistingConfirm',
  MigrationOEIEOLAcountNew = 'MigrationOEIEOLAcountNew',
  ConsentOAuth = 'ConsentOAuth',
  ConsentOAuthSuccess = 'ConsentOAuthSuccess',
}

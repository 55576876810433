import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as LgUserReducer from './user.reducer';
import { LgCredentials, LgUserState } from './user.state';

export const selectUser = createFeatureSelector<LgUserState>(LgUserReducer.userFeatureKey);

export const selectCredentials = createSelector(
  selectUser,
  (state: LgUserState) => state.credentials,
);

export const selectEmailAddress = createSelector(
  selectCredentials,
  (state: LgCredentials) => state.emailAddress,
);

export const selectEnvironments = createSelector(
  selectUser,
  (state: LgUserState) => state.environments,
);

export const selectPreferredEnvironment = createSelector(
  selectUser,
  (state: LgUserState) => state.preferredEnvironment,
);

export const selectNotification = createSelector(
  selectUser,
  (state: LgUserState) => state.notification,
);

export const selectIdentityType = createSelector(
  selectUser,
  (state: LgUserState) => state.identityType,
);

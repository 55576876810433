import { createAction, props } from '@ngrx/store';

import { ForgotPasswordModel, IdentityType, UserInfoRequestModel } from '@api';

import { LgErrorMessage } from '../models/error-message.model';

export const SetEmailAddress = createAction(
  '[User] Set Email Address',
  props<{ emailAddress: string }>(),
);

export const SetActiveEnvironment = createAction(
  '[User] Set Active Environment',
  props<{ environment: string }>(),
);

export const SetEnvironments = createAction(
  '[User] Set Environments',
  props<{ environments: string[] | null }>(),
);

export const ResetUser = createAction('[User] Reset User User');

export const LoadUserInfoPending = createAction(
  '[User] Load User Info',
  props<{ payload: UserInfoRequestModel }>(),
);

export const LoadUserInfoFulfilled = createAction(
  '[User] Load User Info Fulfilled',
  props<{ identityType: IdentityType }>(),
);

export const LoadUserInfoRejected = createAction(
  '[User] Load User Info Rejected',
  props<{ errors: LgErrorMessage[] }>(),
);

export const LoadEnvironments = createAction(
  '[User] Load Environments',
  props<{ environments: string[] | null }>(),
);

export const LoadEnvironmentsPending = createAction(
  '[User] Load Environments Pending'
);

export const LoadEnvironmentsFulfilled = createAction(
  '[User] Load Environments Fulfilled',
  props<{
    environments: string[] | null;
    preferredEnvironment: string | null;
    notification: string | null;
  }>(),
);

export const LoadEnvironmentsRejected = createAction(
  '[User] Load Environments Rejected',
  props<{ errors: LgErrorMessage[] }>(),
);

export const ResetPasswordPending = createAction(
  '[User] Reset Password Pending',
  props<{ payload: ForgotPasswordModel }>(),
);

export const ResetPasswordFulfilled = createAction(
  '[User] Reset Password Fulfilled'
);

export const ResetPasswordRejected = createAction(
  '[User] Reset Password Rejected',
  props<{ errors: LgErrorMessage[] }>(),
);

import { createAction, props } from '@ngrx/store';

import { GetConsentInfoResult, SaveConsentInfoModel } from '@api';

import { LgErrorMessage } from '../models/error-message.model';

export const GetConsentInfoPending = createAction(
  '[Consent] Get Consent Info Pending'
);

export const GetConsentInfoFulfilled = createAction(
  '[Consent] Get Consent Info Fulfilled',
  props<{ result: GetConsentInfoResult }>()
);

export const GetConsentInfoRejected = createAction(
  '[Consent] Get Consent Info Rejected',
  props<{ errors: LgErrorMessage[] }>()
);

export const SaveConsentInfoPending = createAction(
  '[Consent] Save Consent Info Pending',
  props<{ payload: SaveConsentInfoModel }>()
);

export const SaveConsentInfoFulfilled = createAction(
  '[Consent] Save Consent Info Fulfilled',
  props<{ redirectUrl: string | undefined }>()
);

export const SaveConsentInfoRejected = createAction(
  '[Consent] Save Consent Info Rejected',
  props<{ errors: LgErrorMessage[] }>()
);

<div
  [ngClass]="{
    'w-[4px] h-[4px]': size === 'xx-small',
    'w-[8px] h-[8px]': size === 'x-small',
    'w-[12px] h-[12px]': size === 'small',
    'w-[16px] h-[16px]': size === 'medium',
    'w-[24px] h-[24px]': size === 'large',
    'w-[32px] h-[32px]': size === 'x-large',
    'w-0 h-0': size === 'none'
  }"
></div>

import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as LgMigrationReducer from './migration.reducer';
import { LgMigrationState } from './migration.state';

export const selectMigration = createFeatureSelector<LgMigrationState>(
  LgMigrationReducer.migrationFeatureKey
);

export const selectResult = createSelector(
  selectMigration,
  (state: LgMigrationState) => state.result
);

export const selectOeiUser = createSelector(
  selectMigration,
  (state: LgMigrationState) => state.result?.oeiUser
);

export const selectEolDetails = createSelector(
  selectMigration,
  (state: LgMigrationState) => state.eolDetails
);

export const selectMigrationErrors = createSelector(
  selectMigration,
  (state: LgMigrationState) => state.errors
);

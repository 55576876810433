import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

import { ApiException, OeiMigrationApiService, OeiMigrationStep } from '@api';

import { AppState } from '../app.state';
import { LgErrorMessage } from '../models/error-message.model';
import * as LgMigrationActions from './migration.actions';

@Injectable()
export class LgMigrationEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly migrationApiService: OeiMigrationApiService,
    private readonly store: Store<AppState>
  ) {}

  startMigrationCheck$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LgMigrationActions.MigrateCheckMigrationPending),
      mergeMap(() =>
        this.migrationApiService.checkStartMigration().pipe(
          map((res) =>
            LgMigrationActions.MigrateCheckMigrationFulfilled({
              result: res,
            })
          ),
          catchError((err: ApiException) => {
            const errors: LgErrorMessage[] = [];

            errors.push({
              title: 'Migratie foutmelding',
              message: `${err.result.message} [${err.result.result}]`,
              statusCode: err.status,
              result: err.result.result,
            });

            return of(
              LgMigrationActions.MigrateCheckMigrationRejected({
                errors,
              })
            );
          })
        )
      )
    )
  );

  completeMigrationCheck$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LgMigrationActions.MigrateCompleteMigrationPending),
      mergeMap(() =>
        this.migrationApiService.migrationDone().pipe(
          map((res) =>
            LgMigrationActions.MigrateCompleteMigrationFulfilled({
              result: res,
            })
          ),
          catchError((err: ApiException) => {
            const errors: LgErrorMessage[] = [];

            errors.push({
              title: 'Migratie foutmelding',
              message: `${err.result.message} [${err.result.result}]`,
              statusCode: err.status,
              result: err.result.result,
            });

            return of(
              LgMigrationActions.MigrateCompleteMigrationRejected({
                errors,
              })
            );
          })
        )
      )
    )
  );

  initialMigrationSendEmailToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LgMigrationActions.MigrateInitialSendEmailTokenPending),
      mergeMap(() =>
        this.migrationApiService.initialMigrationSendUserEmailToken().pipe(
          map((res) =>
            LgMigrationActions.MigrateInitialSendEmailTokenFulfilled({
              result: res,
            })
          ),
          catchError((err: ApiException) => {
            const errors: LgErrorMessage[] = [];

            errors.push({
              title: 'Migratie foutmelding',
              message: `${err.result.message} [${err.result.result}]`,
              statusCode: err.status,
              result: err.result.result,
            });

            return of(
              LgMigrationActions.MigrateInitialSendEmailTokenRejected({
                errors,
              })
            );
          })
        )
      )
    )
  );

  intialVerifyEmailToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LgMigrationActions.MigrateInitialVerifyEmailTokenPending),
      mergeMap((action) =>
        this.migrationApiService
          .initialMigrationVerifyUserEmailToken({
            token: action.payload,
          })
          .pipe(
            map((res) =>
              LgMigrationActions.MigrateInitialVerifyEmailTokenFulfilled({
                result: res,
              })
            ),
            catchError((err: ApiException) => {
              const errors: LgErrorMessage[] = [];

              if (
                err.result.result ===
                  OeiMigrationStep.ErrorInitialEmailTokenInvalid ||
                err.result.result ===
                  OeiMigrationStep.ErrorInitialEmailTokenExpired
              ) {
                errors.push({
                  title: 'Emailverificatie mislukt',
                  message:
                    'De ingevulde verificatiecode is niet correct of is mogelijk verlopen. De verificatiecode is te vinden in de email die naar je toe is verzonden.',
                  statusCode: err.status,
                  result: err.result.result,
                });
              }

              if (
                err.result.result ===
                OeiMigrationStep.ErrorInitialEmailTokenMaxAttempts
              ) {
                errors.push({
                  title: 'Limiet bereikt',
                  message:
                    'Het limiet voor het maximale aantal inlogpogingen is bereikt. Probeer opnieuw in te loggen.',
                  statusCode: err.status,
                  result: err.result.result,
                });
              }

              return of(
                LgMigrationActions.MigrateInitialVerifyEmailTokenRejected({
                  errors,
                })
              );
            })
          )
      )
    )
  );

  initialMigrationSignInOeiUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LgMigrationActions.MigrateInitialSignInOeiUserPending),
      mergeMap(() =>
        this.migrationApiService.initialMigrationSignInOeiUser().pipe(
          map((res) =>
            LgMigrationActions.MigrateInitialSignInOeiUserFulfilled({
              result: res,
            })
          ),
          tap((action) => {
            if (action.result.redirectUrl)
              window.location.href = action.result.redirectUrl;
          }),
          catchError((err: ApiException) => {
            const errors: LgErrorMessage[] = [];

            errors.push({
              title: 'Migratie foutmelding',
              message: `${err.result.message} [${err.result.result}]`,
              statusCode: err.status,
              result: err.result.result,
            });

            return of(
              LgMigrationActions.MigrateInitialSignInOeiUserRejected({
                errors,
              })
            );
          })
        )
      )
    )
  );

  userMigrationSendEmailToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LgMigrationActions.MigrateUserSendEmailTokenPending),
      mergeMap(() =>
        this.migrationApiService.userMigrationSendUserEmailToken().pipe(
          map((res) =>
            LgMigrationActions.MigrateUserSendEmailTokenFulfilled({
              result: res,
            })
          ),
          catchError((err: ApiException) => {
            const errors: LgErrorMessage[] = [];

            errors.push({
              title: 'Migratie foutmelding',
              message: `${err.result.message} [${err.result.result}]`,
              statusCode: err.status,
              result: err.result.result,
            });

            return of(
              LgMigrationActions.MigrateUserSendEmailTokenRejected({
                errors,
              })
            );
          })
        )
      )
    )
  );

  userVerifyEmailToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LgMigrationActions.MigrateUserVerifyEmailTokenPending),
      mergeMap((action) =>
        this.migrationApiService
          .userMigrationVerifyUserEmailToken({
            token: action.payload,
          })
          .pipe(
            map((res) =>
              LgMigrationActions.MigrateUserVerifyEmailTokenFulfilled({
                result: res,
              })
            ),
            catchError((err: ApiException) => {
              const errors: LgErrorMessage[] = [];

              if (
                err.result.result ===
                  OeiMigrationStep.ErrorUserEmailTokenInvalid ||
                err.result.result ===
                  OeiMigrationStep.ErrorUserEmailTokenExpired
              ) {
                errors.push({
                  title: 'Emailverificatie mislukt',
                  message:
                    'De ingevulde verificatiecode is niet correct of is mogelijk verlopen. De verificatiecode is te vinden in de email die naar je toe is verzonden.',
                  statusCode: err.status,
                  result: err.result.result,
                });
              }

              if (
                err.result.result ===
                OeiMigrationStep.ErrorUserEmailTokenMaxAttempts
              ) {
                errors.push({
                  title: 'Limiet bereikt',
                  message:
                    'Het limiet voor het maximale aantal inlogpogingen is bereikt. Probeer opnieuw in te loggen.',
                  statusCode: err.status,
                  result: err.result.result,
                });
              }

              return of(
                LgMigrationActions.MigrateUserVerifyEmailTokenRejected({
                  errors,
                })
              );
            })
          )
      )
    )
  );

  userMigrationLinkUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LgMigrationActions.MigrateUserLinkUserPending),
      mergeMap(() =>
        this.migrationApiService.userMigrationLinkUser().pipe(
          map((res) =>
            LgMigrationActions.MigrateUserLinkUserFulfilled({
              result: res,
            })
          ),

          catchError((err: ApiException) => {
            const errors: LgErrorMessage[] = [];

            errors.push({
              title: 'Migratie foutmelding',
              message: `${err.result.message} [${err.result.result}]`,
              statusCode: err.status,
              result: err.result.result,
            });

            return of(
              LgMigrationActions.MigrateUserLinkUserRejected({
                errors,
              })
            );
          })
        )
      )
    )
  );

  initialMigrationNewEolAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LgMigrationActions.MigrateInitialMigrationNewEolAccountPending),
      mergeMap(() =>
        this.migrationApiService.initialMigrationNewEolAccount().pipe(
          map((res) =>
            LgMigrationActions.MigrateInitialMigrationNewEolAccountFulfilled({
              result: res,
            })
          ),
          catchError((err: ApiException) => {
            const errors: LgErrorMessage[] = [];

            errors.push({
              title: 'Migratie foutmelding',
              message: `${err.result.message} [${err.result.result}]`,
              statusCode: err.status,
              result: err.result.result,
            });

            return of(
              LgMigrationActions.MigrateInitialMigrationNewEolAccountRejected({
                errors,
              })
            );
          })
        )
      )
    )
  );

  initialMigrationSignInExistingEolAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LgMigrationActions.MigrateInitialSignInExistingEolAccountPending),
      mergeMap(() =>
        this.migrationApiService
          .initialMigrationSignInExistingEolAccount()
          .pipe(
            map((res) =>
              LgMigrationActions.MigrateInitialSignInExistingEolAccountFulfilled(
                {
                  result: res,
                }
              )
            ),
            tap((action) => {
              if (action.result.redirectUrl)
                window.location.href = action.result.redirectUrl;
            }),
            catchError((err: ApiException) => {
              const errors: LgErrorMessage[] = [];

              errors.push({
                title: 'Migratie foutmelding',
                message: `${err.result.message} [${err.result.result}]`,
                statusCode: err.status,
                result: err.result.result,
              });

              return of(
                LgMigrationActions.MigrateInitialSignInExistingEolAccountRejected(
                  {
                    errors,
                  }
                )
              );
            })
          )
      )
    )
  );

  initialMigrationEolDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LgMigrationActions.MigrateInitialEolDetailsPending),
      mergeMap(() =>
        this.migrationApiService.initialMigrationEolDetails().pipe(
          map((res) =>
            LgMigrationActions.MigrateInitialEolDetailsFulfilled({
              eolDetails: res,
            })
          ),
          catchError((err: ApiException) => {
            const errors: LgErrorMessage[] = [];

            errors.push({
              title: 'Migratie foutmelding',
              message: `${err.result.message} [${err.result.result}]`,
              statusCode: err.status,
              result: err.result.result,
            });

            return of(
              LgMigrationActions.MigrateInitialEolDetailsRejected({
                errors,
              })
            );
          })
        )
      )
    )
  );

  initialMigrationMigrateExistingEolAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LgMigrationActions.MigrateInitialExistingEolAccountPending),
      mergeMap(() =>
        this.migrationApiService
          .initialMigrationMigrateExistingEolAccount()
          .pipe(
            map((res) =>
              LgMigrationActions.MigrateInitialExistingEolAccountFulfilled({
                result: res,
              })
            ),
            catchError((err: ApiException) => {
              const errors: LgErrorMessage[] = [];

              errors.push({
                title: 'Migratie foutmelding',
                message: `${err.result.message} [${err.result.result}]`,
                statusCode: err.status,
                result: err.result.result,
              });

              return of(
                LgMigrationActions.MigrateInitialExistingEolAccountRejected({
                  errors,
                })
              );
            })
          )
      )
    )
  );

  initialMigrationGetCurrentState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LgMigrationActions.MigrateInitialGetCurrentStatePending),
      mergeMap(() =>
        this.migrationApiService.getCurrentState().pipe(
          map((res) =>
            LgMigrationActions.MigrateInitialGetCurrentStateFulfilled({
              result: res,
            })
          ),
          catchError((err: ApiException) => {
            const errors: LgErrorMessage[] = [];

            errors.push({
              title: 'Migratie foutmelding',
              message: `${err.result.message} [${err.result.result}]`,
              statusCode: err.status,
              result: err.result.result,
            });

            return of(
              LgMigrationActions.MigrateInitialGetCurrentStateRejected({
                errors,
              })
            );
          })
        )
      )
    )
  );
}

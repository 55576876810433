import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LgIconModule } from '@components/icon/icon.module';

import { LgLinkComponent } from './link.component';

@NgModule({
  imports: [CommonModule, LgIconModule],
  declarations: [LgLinkComponent],
  exports: [LgLinkComponent],
})
export class LgLinkModule {}

import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { UserEnvironmentsResponseModel } from '@api';

import * as LgUserActions from './user.actions';
import { LgUserState } from './user.state';

export const userFeatureKey = 'user';

export const adapter: EntityAdapter<UserEnvironmentsResponseModel> =
  createEntityAdapter<UserEnvironmentsResponseModel>();

export const initialState: LgUserState = {
  credentials: {
    emailAddress: null,
    environment: null,
  },
  environments: null,
  preferredEnvironment: null,
  notification: null,
  identityType: null,
  isLoading: false,
  errors: null,
};

export const reducer = createReducer(
  initialState,
  on(LgUserActions.SetEmailAddress, (state, { emailAddress }) => ({
    ...state,
    credentials: {
      ...state.credentials,
      emailAddress,
    },
  })),
  on(LgUserActions.SetActiveEnvironment, (state, { environment }) => ({
    ...state,
    credentials: {
      ...state.credentials,
      environment,
    },
  })),
  on(LgUserActions.SetEnvironments, (state, { environments }) => ({
    ...state,
    environments,
  })),
  on(LgUserActions.ResetUser, (state) => ({
    ...state,
    ...initialState,
  })),
  on(LgUserActions.LoadUserInfoPending, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(LgUserActions.LoadUserInfoFulfilled, (state, { identityType }) => ({
    ...state,
    identityType,
    isLoading: false,
    errors: null,
  })),
  on(LgUserActions.LoadUserInfoRejected, (state, { errors }) => ({
    ...state,
    identityType: null,
    isLoading: false,
    errors,
  })),
  on(LgUserActions.LoadEnvironmentsPending, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(
    LgUserActions.LoadEnvironmentsFulfilled,
    (state, { environments, preferredEnvironment, notification }) => ({
      ...state,
      environments,
      preferredEnvironment,
      notification,
      isLoading: false,
      errors: null,
    }),
  ),
  on(LgUserActions.LoadEnvironmentsRejected, (state, { errors }) => ({
    ...state,
    environments: [],
    isLoading: false,
    errors,
  })),
  on(LgUserActions.ResetPasswordPending, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(LgUserActions.ResetPasswordFulfilled, (state) => ({
    ...state,
    isLoading: false,
    errors: null,
  })),
  on(LgUserActions.ResetPasswordRejected, (state, { errors }) => ({
    ...state,
    isLoading: false,
    errors,
  })),
);

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { ApiException, Oauth2ApiService } from '@api';

import { AppState } from '../app.state';
import { LgErrorMessage } from '../models/error-message.model';
import * as LgConsentActions from './consent.actions';

@Injectable()
export class LgConsentEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly oauth2ApiService: Oauth2ApiService,
    private readonly store: Store<AppState>
  ) {}

  getConsentInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LgConsentActions.GetConsentInfoPending),
      mergeMap(() =>
        this.oauth2ApiService.getConsentInfo().pipe(
          map((res) =>
            LgConsentActions.GetConsentInfoFulfilled({ result: res })
          ),

          catchError((err: ApiException) => {
            const errors: LgErrorMessage[] = [];

            errors.push({
              message: 'Unhandled error',
              statusCode: err.status,
              result: err.result.result,
            });

            return of(
              LgConsentActions.GetConsentInfoRejected({
                errors,
              })
            );
          })
        )
      )
    )
  );

  saveConsentInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LgConsentActions.SaveConsentInfoPending),
      mergeMap((action) =>
        this.oauth2ApiService
          .saveConsent({
            mainClientGroupIds: action.payload.mainClientGroupIds,
            scopes: action.payload.scopes,
          })
          .pipe(
            map((res) =>
              LgConsentActions.SaveConsentInfoFulfilled({
                redirectUrl: res.redirectUrl,
              })
            ),

            catchError((err: ApiException) => {
              const errors: LgErrorMessage[] = [];

              errors.push({
                message: 'Unhandled error',
                statusCode: err.status,
                result: err.result.result,
              });

              return of(
                LgConsentActions.SaveConsentInfoRejected({
                  errors,
                })
              );
            })
          )
      )
    )
  );
}

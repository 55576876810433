import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { UserEnvironmentsResponseModel } from '@api';

import { EAuthenticationStep } from '../models/authentication-step.enum';
import { EContext } from '../models/context.enum';
import * as LgAuthenticationActions from './auth.actions';
import { LgAuthenticationState } from './auth.state';

export const authenticationFeatureKey = 'auth';

export const adapter: EntityAdapter<UserEnvironmentsResponseModel> =
  createEntityAdapter<UserEnvironmentsResponseModel>();

export const initialState: LgAuthenticationState = {
  authenticationFlow: {
    activeAuthenticationStep: EAuthenticationStep.Email,
    progress: null,
  },
  context: EContext.Default,
  result: null,
  forbiddenReason: null,
  isLoading: false,
  errors: null,
};

export const reducer = createReducer(
  initialState,
  on(
    LgAuthenticationActions.SetAuthenticationFlow,
    (state, { activeAuthenticationStep, progress }) => ({
      ...state,
      authenticationFlow: {
        activeAuthenticationStep,
        progress,
      },
      errors: null,
    }),
  ),

  on(LgAuthenticationActions.ResetAuthenticationProgressStep, (state) => ({
    ...state,
    authenticationFlow: {
      activeAuthenticationStep:
        state.authenticationFlow.activeAuthenticationStep,
      progress: null,
    },
    errors: null,
  })),

  on(LgAuthenticationActions.AuthenticateOneExactIdentityPending, (state) => ({
    ...state,
    isLoading: true,
    errors: null,
  })),
  on(
    LgAuthenticationActions.AuthenticateOneExactIdentityFulfilled,
    (state, { result }) => ({
      ...state,
      signinUrl: result.signInUrl,
      isLoading: false,
      errors: null,
    })
  ),
  on(
    LgAuthenticationActions.AuthenticateOneExactIdentityRejected,
    (state, { errors }) => ({
      ...state,
      isLoading: false,
      errors,
    })
  ),
  on(LgAuthenticationActions.AuthenticateTraditionalLoginPending, (state) => ({
    ...state,
    isLoading: true,
    errors: null,
  })),
  on(
    LgAuthenticationActions.AuthenticateTraditionalLoginFulfilled,
    (state, { result }) => ({
      ...state,
      result,
      isLoading: false,
      errors: null,
    })
  ),
  on(
    LgAuthenticationActions.AuthenticateTraditionalLoginRejected,
    (state, { errors }) => ({
      ...state,
      result: null,
      isLoading: false,
      errors,
    })
  ),
  on(LgAuthenticationActions.AuthenticateMFAPending, (state) => ({
    ...state,
    isLoading: true,
    errors: null,
  })),
  on(LgAuthenticationActions.AuthenticateMFAFulfilled, (state, { result }) => ({
    ...state,
    result,
    isLoading: false,
    errors: null,
  })),
  on(LgAuthenticationActions.AuthenticateMFARejected, (state, { errors }) => ({
    ...state,
    result: null,
    isLoading: false,
    errors,
  })),
  on(
    LgAuthenticationActions.AuthenticateRedirectExactFiscaalPending,
    (state) => ({
      ...state,
      isLoading: true,
      errors: null,
    })
  ),
  on(
    LgAuthenticationActions.AuthenticateRedirectExactFiscaalFulfilled,
    (state) => ({
      ...state,
      isLoading: false,
      errors: null,
    })
  ),
  on(
    LgAuthenticationActions.AuthenticateRedirectExactFiscaalRejected,
    (state, { errors }) => ({
      ...state,
      result: null,
      isLoading: false,
      errors,
    })
  ),
  on(LgAuthenticationActions.AuthenticateClearLoginSessionPending, (state) => ({
    ...state,
    isLoading: true,
    errors: null,
  })),
  on(
    LgAuthenticationActions.AuthenticateClearLoginSessionFulfilled,
    (state) => ({
      ...state,
      result: null,
      isLoading: false,
      errors: null,
    })
  ),
  on(
    LgAuthenticationActions.AuthenticateClearLoginSessionRejected,
    (state, { errors }) => ({
      ...state,
      result: null,
      isLoading: false,
      errors,
    })
  ),
  on(LgAuthenticationActions.AuthenticateSetReturnURlPending, (state) => ({
    ...state,
    isLoading: true,
    errors: null,
  })),
  on(LgAuthenticationActions.AuthenticateSetReturnURlFulfilled, (state) => ({
    ...state,
    isLoading: false,
    errors: null,
  })),
  on(
    LgAuthenticationActions.AuthenticateSetReturnURlRejected,
    (state, { errors }) => ({
      ...state,
      isLoading: false,
      errors,
    })
  ),
  on(
    LgAuthenticationActions.AuthenticateGetForbiddenReasonPending,
    (state) => ({
      ...state,
      forbiddenReason: null,
      isLoading: true,
      errors: null,
    })
  ),
  on(
    LgAuthenticationActions.AuthenticateGetForbiddenReasonFulfilled,
    (state, { forbiddenReason }) => ({
      ...state,
      forbiddenReason,
      isLoading: false,
      errors: null,
    }),
  ),
  on(
    LgAuthenticationActions.AuthenticateGetForbiddenReasonRejected,
    (state, { errors }) => ({
      ...state,
      isLoading: false,
      errors,
    })
  ),
  on(LgAuthenticationActions.AuthenticateClearErrors, (state) => ({
    ...state,
    isLoading: false,
    errors: null,
  })),
  on(LgAuthenticationActions.AuthenticateSetContext, (state, { context }) => ({
    ...state,
    context,
  })),
);

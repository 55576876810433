import { Component, Input } from '@angular/core';

export type Size =
  | 'xx-small'
  | 'x-small'
  | 'small'
  | 'medium'
  | 'large'
  | 'x-large'
  | 'xx-large'
  | 'none';

@Component({
  selector: 'lg-spacer',
  templateUrl: './spacer.component.html',
})
export class LgSpacerComponent {
  @Input() size: Size = 'medium';
}

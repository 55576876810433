import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { UserEnvironmentsResponseModel } from '@api';

import * as LgMigrationActions from './migration.actions';
import { LgMigrationState } from './migration.state';

export const migrationFeatureKey = 'migration';

export const adapter: EntityAdapter<UserEnvironmentsResponseModel> =
  createEntityAdapter<UserEnvironmentsResponseModel>();

export const initialState: LgMigrationState = {
  result: null,
  eolDetails: null,
  isLoading: false,
  errors: null,
};

export const reducer = createReducer(
  initialState,
  on(LgMigrationActions.MigrateResetState, (state) => ({
    ...state,
    result: null,
    eolDetails: null,
    isLoading: false,
    errors: null,
  })),
  on(LgMigrationActions.MigrateCheckMigrationPending, (state) => ({
    ...state,
    isLoading: true,
    errors: null,
  })),
  on(
    LgMigrationActions.MigrateCheckMigrationFulfilled,
    (state, { result }) => ({
      ...state,
      result,
      isLoading: false,
      errors: null,
    })
  ),
  on(LgMigrationActions.MigrateCheckMigrationRejected, (state, { errors }) => ({
    ...state,
    isLoading: false,
    errors,
  })),
  on(LgMigrationActions.MigrateCompleteMigrationPending, (state) => ({
    ...state,
    isLoading: true,
    errors: null,
  })),
  on(
    LgMigrationActions.MigrateCompleteMigrationFulfilled,
    (state, { result }) => ({
      ...state,
      result,
      isLoading: false,
      errors: null,
    })
  ),
  on(
    LgMigrationActions.MigrateCompleteMigrationRejected,
    (state, { errors }) => ({
      ...state,
      isLoading: false,
      errors,
    })
  ),
  on(LgMigrationActions.MigrateInitialSignInOeiUserPending, (state) => ({
    ...state,
    isLoading: true,
    errors: null,
  })),
  on(
    LgMigrationActions.MigrateInitialSignInOeiUserFulfilled,
    (state, { result }) => ({
      ...state,
      result,
      isLoading: false,
      errors: null,
    })
  ),
  on(
    LgMigrationActions.MigrateInitialSignInOeiUserRejected,
    (state, { errors }) => ({
      ...state,
      isLoading: false,
      errors,
    })
  ),
  on(LgMigrationActions.MigrateInitialVerifyEmailTokenPending, (state) => ({
    ...state,
    isLoading: true,
    errors: null,
  })),
  on(
    LgMigrationActions.MigrateInitialVerifyEmailTokenFulfilled,
    (state, { result }) => ({
      ...state,
      result,
      isLoading: false,
      errors: null,
    })
  ),
  on(
    LgMigrationActions.MigrateInitialVerifyEmailTokenRejected,
    (state, { errors }) => ({
      ...state,
      isLoading: false,
      errors,
    })
  ),
  on(LgMigrationActions.MigrateUserVerifyEmailTokenPending, (state) => ({
    ...state,
    isLoading: true,
    errors: null,
  })),
  on(
    LgMigrationActions.MigrateUserVerifyEmailTokenFulfilled,
    (state, { result }) => ({
      ...state,
      result,
      isLoading: false,
      errors: null,
    })
  ),
  on(
    LgMigrationActions.MigrateUserVerifyEmailTokenRejected,
    (state, { errors }) => ({
      ...state,
      isLoading: false,
      errors,
    })
  ),
  on(LgMigrationActions.MigrateUserLinkUserPending, (state) => ({
    ...state,
    isLoading: true,
    errors: null,
  })),
  on(LgMigrationActions.MigrateUserLinkUserFulfilled, (state, { result }) => ({
    ...state,
    result,
    isLoading: false,
    errors: null,
  })),
  on(LgMigrationActions.MigrateUserLinkUserRejected, (state, { errors }) => ({
    ...state,
    isLoading: false,
    errors,
  })),
  on(
    LgMigrationActions.MigrateInitialMigrationNewEolAccountPending,
    (state) => ({
      ...state,
      isLoading: true,
      errors: null,
    })
  ),
  on(
    LgMigrationActions.MigrateInitialMigrationNewEolAccountFulfilled,
    (state, { result }) => ({
      ...state,
      result,
      isLoading: false,
      errors: null,
    })
  ),
  on(
    LgMigrationActions.MigrateInitialMigrationNewEolAccountRejected,
    (state, { errors }) => ({
      ...state,
      isLoading: false,
      errors,
    })
  ),
  on(
    LgMigrationActions.MigrateInitialSignInExistingEolAccountPending,
    (state) => ({
      ...state,
      isLoading: true,
      errors: null,
    })
  ),
  on(
    LgMigrationActions.MigrateInitialSignInExistingEolAccountFulfilled,
    (state, { result }) => ({
      ...state,
      result,
      isLoading: false,
      errors: null,
    })
  ),
  on(
    LgMigrationActions.MigrateInitialSignInExistingEolAccountRejected,
    (state, { errors }) => ({
      ...state,
      isLoading: false,
      errors,
    })
  ),
  on(LgMigrationActions.MigrateInitialEolDetailsPending, (state) => ({
    ...state,
    isLoading: true,
    errors: null,
  })),
  on(
    LgMigrationActions.MigrateInitialEolDetailsFulfilled,
    (state, { eolDetails }) => ({
      ...state,
      eolDetails,
      isLoading: false,
      errors: null,
    })
  ),
  on(
    LgMigrationActions.MigrateInitialEolDetailsRejected,
    (state, { errors }) => ({
      ...state,
      isLoading: false,
      errors,
    })
  ),
  on(LgMigrationActions.MigrateInitialExistingEolAccountPending, (state) => ({
    ...state,
    isLoading: true,
    errors: null,
  })),
  on(
    LgMigrationActions.MigrateInitialExistingEolAccountFulfilled,
    (state, { result }) => ({
      ...state,
      result,
      isLoading: false,
      errors: null,
    })
  ),
  on(
    LgMigrationActions.MigrateInitialExistingEolAccountRejected,
    (state, { errors }) => ({
      ...state,
      isLoading: false,
      errors,
    })
  ),
  on(LgMigrationActions.MigrateInitialGetCurrentStatePending, (state) => ({
    ...state,
    isLoading: true,
    errors: null,
  })),
  on(
    LgMigrationActions.MigrateInitialGetCurrentStateFulfilled,
    (state, { result }) => ({
      ...state,
      result,
      isLoading: false,
      errors: null,
    })
  ),
  on(
    LgMigrationActions.MigrateInitialGetCurrentStateRejected,
    (state, { errors }) => ({
      ...state,
      isLoading: false,
      errors,
    })
  )
);

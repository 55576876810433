import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LgSpacerComponent } from './spacer.component';

@NgModule({
  imports: [CommonModule],
  declarations: [LgSpacerComponent],
  exports: [LgSpacerComponent],
})
export class LgSpacerModule {}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'lg-paragraph',
  templateUrl: './paragraph.component.html',
})
export class LgParagraphComponent {
  @Input() size: 'small' | 'medium' = 'medium';

  @Input() fontStyle: 'normal' | 'bold' = 'normal';
}

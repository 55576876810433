import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { environment } from '@environments/environment';

import { LgAuthenticationFeatureSliceModule } from './auth/auth-feature-slice.module';
import { LgConsentFeatureSliceModule } from './consent/consent-feature-slice.module';
import { LgMigrationFeatureSliceModule } from './migration/migration-feature-slice.module';
import { LgUserFeatureSliceModule } from './user/user-feature-slice.module';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot(
      {},
      {
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
          strictStateSerializability: true,
          strictActionSerializability: true,
          strictActionWithinNgZone: true,
          strictActionTypeUniqueness: true,
        },
      },
    ),
    EffectsModule.forRoot([]),
    LgAuthenticationFeatureSliceModule,
    LgUserFeatureSliceModule,
    LgMigrationFeatureSliceModule,
    LgConsentFeatureSliceModule,
    StoreDevtoolsModule.instrument({
      name: 'Login Gemak Store',
      logOnly: environment.production,
      autoPause: true,
      connectInZone: true,
    }),
  ],
})
export class LgStoreModule {}

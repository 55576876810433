import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { LgMigrationEffects } from './migration.effects';
import * as LgMigrationReducer from './migration.reducer';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(LgMigrationReducer.migrationFeatureKey, LgMigrationReducer.reducer),
    EffectsModule.forFeature([LgMigrationEffects]),
  ],
  providers: [LgMigrationEffects],
})
export class LgMigrationFeatureSliceModule {}

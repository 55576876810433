import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LgParagraphComponent } from './paragraph.component';

@NgModule({
  imports: [CommonModule],
  declarations: [LgParagraphComponent],
  exports: [LgParagraphComponent],
})
export class LgParagraphModule {}

import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as LgConsentReducer from './consent.reducer';
import { LgConsentState } from './consent.state';

export const selectConsent = createFeatureSelector<LgConsentState>(
  LgConsentReducer.consentFeatureKey,
);

export const selectConsentInfoResult = createSelector(
  selectConsent,
  (state: LgConsentState) => state.result,
);

export const selectConsentRedirectUrl = createSelector(
  selectConsent,
  (state: LgConsentState) => state.redirectUrl,
);

export const selectConsentIsLoading = createSelector(
  selectConsent,
  (state: LgConsentState) => state.isLoading,
);

import { createAction, props } from '@ngrx/store';

import {
  AuthenticateFgModel,
  AuthenticateOneExactIdentityRequestModel,
  AuthenticateOneExactIdentityResponseModel,
  ForbiddenReasonModel,
  RedirectToFiscaalGemakResponseModel,
  SigninFgResultModel,
  ValidateMfaFgModel,
} from '@api';

import { EAuthenticationStep } from '../models/authentication-step.enum';
import { EContext } from '../models/context.enum';
import { LgErrorMessage } from '../models/error-message.model';
import { LgAuthenticationProgress } from './auth.state';

export const SetAuthenticationFlow = createAction(
  '[Auth] Set Authentication Flow',
  props<{
    activeAuthenticationStep: EAuthenticationStep;
    progress: LgAuthenticationProgress;
  }>()
);
export const ResetAuthenticationProgressStep = createAction(
  '[Auth] Reset Authentication Progress Step'
);

export const AuthenticateOneExactIdentityPending = createAction(
  '[Auth] Authentication Exact One Identity Pending',
  props<{ payload: AuthenticateOneExactIdentityRequestModel }>()
);

export const AuthenticateOneExactIdentityFulfilled = createAction(
  '[Auth] Authentication Exact One Identity Fulfilled',
  props<{ result: AuthenticateOneExactIdentityResponseModel }>()
);

export const AuthenticateOneExactIdentityRejected = createAction(
  '[Auth] Authentication Exact One Identity Rejected',
  props<{ errors: LgErrorMessage[] }>()
);

export const AuthenticateTraditionalLoginPending = createAction(
  '[Auth] Authentication Traditional Login Pending',
  props<{ payload: AuthenticateFgModel }>()
);

export const AuthenticateTraditionalLoginFulfilled = createAction(
  '[Auth] Authentication Traditional Login Fulfilled',
  props<{ result: SigninFgResultModel }>()
);

export const AuthenticateTraditionalLoginRejected = createAction(
  '[Auth] Authentication Traditional Login Rejected',
  props<{ errors: LgErrorMessage[] }>()
);

export const AuthenticateRedirectExactFiscaalPending = createAction(
  '[Auth] Redirect to Exact Fiscaal Pending'
);

export const AuthenticateRedirectExactFiscaalFulfilled = createAction(
  '[Auth] Redirect to Exact Fiscaal Fulfilled',
  props<{ result: RedirectToFiscaalGemakResponseModel }>()
);

export const AuthenticateRedirectExactFiscaalRejected = createAction(
  '[Auth] Redirect to Exact Fiscaal Rejected',
  props<{ errors: LgErrorMessage[] }>()
);

export const AuthenticateMFAPending = createAction(
  '[Auth] MFA Login Pending',
  props<{ payload: ValidateMfaFgModel }>()
);

export const AuthenticateMFAFulfilled = createAction(
  '[Auth] MFA Login Fulfilled',
  props<{ result: SigninFgResultModel }>()
);

export const AuthenticateMFARejected = createAction(
  '[Auth] Authentication MFA Login Rejected',
  props<{ errors: LgErrorMessage[] }>()
);

export const AuthenticateClearLoginSessionPending = createAction(
  '[Auth] Clear Login Session Pending'
);

export const AuthenticateClearLoginSessionFulfilled = createAction(
  '[Auth] Clear Login Session Fulfilled'
);

export const AuthenticateClearLoginSessionRejected = createAction(
  '[Auth] Clear Login Session Rejected',
  props<{ errors: LgErrorMessage[] }>()
);

export const AuthenticateSendEmailVerificationCodePending = createAction(
  '[Auth] Send Email Verification Code Pending',
  props<{ payload: string }>()
);

export const AuthenticateSendEmailVerificationCodeFulfilled = createAction(
  '[Auth] Send Email Verification Code Fulfilled'
);

export const AuthenticateSendEmailVerificationCodeRejected = createAction(
  '[Auth] Send Email Verification Code Rejected',
  props<{ errors: LgErrorMessage[] }>()
);

export const AuthenticateSetReturnURlPending = createAction(
  '[Auth] Set Return URL Pending',
  props<{ payload: string }>()
);

export const AuthenticateSetReturnURlFulfilled = createAction(
  '[Auth] Set Return URL Fulfilled'
);

export const AuthenticateSetReturnURlRejected = createAction(
  '[Auth] Set Return URL Rejected',
  props<{ errors: LgErrorMessage[] }>()
);

export const AuthenticateGetForbiddenReasonPending = createAction(
  '[Auth] Get Forbidden Reason Pending'
);

export const AuthenticateGetForbiddenReasonFulfilled = createAction(
  '[Auth] Get Forbidden Reason Fulfilled',
  props<{ forbiddenReason: ForbiddenReasonModel }>()
);

export const AuthenticateGetForbiddenReasonRejected = createAction(
  '[Auth] Get Forbidden Reason Rejected',
  props<{ errors: LgErrorMessage[] }>()
);

export const AuthenticateClearErrors = createAction(
  '[Auth] Authenticate Clear Errors'
);

export const AuthenticateSetContext = createAction(
  '[Auth] Set Context',
  props<{ context: EContext }>()
);

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';

import {
  ApiException,
  FgMfaVerificationType,
  ForbiddenReason,
  SigninApiService,
  SigninFgResult,
} from '@api';

import { AppState } from '../app.state';
import { LgErrorMessage } from '../models/error-message.model';
import * as LgUserSelectors from '../user/user.selector';
import * as LgAuthenticationActions from './auth.actions';

@Injectable()
export class LgAuthenticationEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly signinApiService: SigninApiService,
    private readonly store: Store<AppState>
  ) {}

  authenticateOneExactIdentity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LgAuthenticationActions.AuthenticateOneExactIdentityPending),
      withLatestFrom(this.store.select(LgUserSelectors.selectEmailAddress)),
      mergeMap(([action, emailAddress]) =>
        this.signinApiService
          .authenticateOneExactIdentity({
            ...action.payload,
            userName: emailAddress as string,
          })
          .pipe(
            map((res) =>
              LgAuthenticationActions.AuthenticateOneExactIdentityFulfilled({
                result: res,
              })
            ),
            tap((action) => {
              if (action.result.signInUrl)
                window.location.href = action.result.signInUrl;
            }),

            catchError((err: ApiException) => {
              const errors: LgErrorMessage[] = [];

              errors.push({
                message: 'Unhandled error',
                statusCode: err.status,
                result: err.result.result,
              });

              return of(
                LgAuthenticationActions.AuthenticateOneExactIdentityRejected({
                  errors,
                })
              );
            })
          )
      )
    )
  );

  authenticateFg$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LgAuthenticationActions.AuthenticateTraditionalLoginPending),
      withLatestFrom(this.store.select(LgUserSelectors.selectEmailAddress)),
      mergeMap(([action, emailAddress]) =>
        this.signinApiService
          .authenticateFg({
            ...action.payload,
            userName: emailAddress as string,
          })
          .pipe(
            map((res) =>
              LgAuthenticationActions.AuthenticateTraditionalLoginFulfilled({
                result: res,
              })
            ),

            catchError((err: ApiException) => {
              const errors: LgErrorMessage[] = [];

              if (err.result.result === SigninFgResult.InvalidCredentials) {
                errors.push({
                  title: 'Inloggen mislukt',
                  message:
                    'De combinatie van je gebruikersnaam en wachtwoord is niet gevonden in de geselecteerde omgeving. Controleer of de ingevulde gegevens correct zijn.',
                  statusCode: err.status,
                  result: err.result.result,
                });
              }

              if (err.result.result === SigninFgResult.LockedOut) {
                errors.push({
                  title: 'Account geblokkeerd',
                  message:
                    'Het limiet voor het maximale aantal inlogpogingen is bereikt. Je account wordt vanuit veiligheidsoverwegingen geblokkeerd. Raadpleeg de beheerder om je account opnieuw te activeren.',
                  statusCode: err.status,
                  result: err.result.result,
                });
              }

              return of(
                LgAuthenticationActions.AuthenticateTraditionalLoginRejected({
                  errors,
                })
              );
            })
          )
      )
    )
  );

  authenticateMFA$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LgAuthenticationActions.AuthenticateMFAPending),
      withLatestFrom(this.store.select(LgUserSelectors.selectEmailAddress)),
      mergeMap(([action, emailAddress]) =>
        this.signinApiService
          .validateMfaFg({
            userName: emailAddress as string,
            trustThisPc: action.payload.trustThisPc,
            verificationToken: action.payload.verificationToken,
            verificationType: action.payload.verificationType,
            recaptchaResponse: action.payload.recaptchaResponse,
          })
          .pipe(
            map((res) =>
              LgAuthenticationActions.AuthenticateMFAFulfilled({ result: res })
            ),
            catchError((err: ApiException) => {
              const errors: LgErrorMessage[] = [];

              if (
                err.result.result === SigninFgResult.InvalidToken ||
                err.result.result === SigninFgResult.InvalidCredentials ||
                err.result.result === SigninFgResult.MfaEmailTokenExpired
              ) {
                if (
                  action.payload.verificationType ===
                  FgMfaVerificationType.AuthenticatorApp
                ) {
                  errors.push({
                    title: 'Tweestapsverificatie mislukt',
                    message:
                      'De ingevulde verificatiecode is niet correct of is mogelijk verlopen. De verificatiecode is te vinden in je authenticator app. Dit is een 6-cijferige code die elke 30 seconden verspringt.',
                    statusCode: err.status,
                    result: err.result.result,
                  });
                }

                if (
                  action.payload.verificationType ===
                  FgMfaVerificationType.Email
                ) {
                  errors.push({
                    title: 'Emailverificatie mislukt',
                    message:
                      'De ingevulde verificatiecode is niet correct of is mogelijk verlopen. De verificatiecode is te vinden in de email die naar je toe is verzonden.',
                    statusCode: err.status,
                    result: err.result.result,
                  });
                }
              }

              if (err.result.result === SigninFgResult.MfaTryCountExeeded) {
                errors.push({
                  title: 'Limiet bereikt',
                  message:
                    'Het limiet voor het maximale aantal inlogpogingen is bereikt. Probeer opnieuw in te loggen.',
                  statusCode: err.status,
                  result: err.result.result,
                });
              }

              return of(
                LgAuthenticationActions.AuthenticateMFARejected({
                  errors,
                })
              );
            })
          )
      )
    )
  );

  redirectToExactFiscaal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LgAuthenticationActions.AuthenticateRedirectExactFiscaalPending),
      mergeMap(() =>
        this.signinApiService.redirectToFiscaalGemak().pipe(
          map((res) =>
            LgAuthenticationActions.AuthenticateRedirectExactFiscaalFulfilled({
              result: res,
            })
          ),
          tap((action) => {
            if (action.result.redirectUrl)
              window.location.href = action.result.redirectUrl;
          }),

          catchError((err: ApiException) => {
            const errors: LgErrorMessage[] = [];

            errors.push({
              message: 'Unhandled error',
              statusCode: err.status,
            });

            return of(
              LgAuthenticationActions.AuthenticateRedirectExactFiscaalRejected({
                errors,
              })
            );
          })
        )
      )
    )
  );

  clearLoginSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LgAuthenticationActions.AuthenticateClearLoginSessionPending),
      mergeMap(() =>
        this.signinApiService.resetSession().pipe(
          map(() =>
            LgAuthenticationActions.AuthenticateClearLoginSessionFulfilled()
          ),
          catchError((err: ApiException) => {
            const errors: LgErrorMessage[] = [];

            errors.push({
              message: 'Unhandled error',
              statusCode: err.status,
              result: err.result.result,
            });

            return of(
              LgAuthenticationActions.AuthenticateClearLoginSessionRejected({
                errors,
              })
            );
          })
        )
      )
    )
  );

  sendMfaEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        LgAuthenticationActions.AuthenticateSendEmailVerificationCodePending
      ),
      withLatestFrom(this.store.select(LgUserSelectors.selectEmailAddress)),
      mergeMap(([action, emailAddress]) =>
        this.signinApiService
          .sendMfaEmailFg({
            userName: emailAddress as string,
            recaptchaResponse: action.payload,
          })
          .pipe(
            map(() =>
              LgAuthenticationActions.AuthenticateSendEmailVerificationCodeFulfilled()
            ),
            catchError((err: ApiException) => {
              const errors: LgErrorMessage[] = [];

              errors.push({
                message: 'Unhandled error',
                statusCode: err.status,
                result: err.result.result,
              });

              return of(
                LgAuthenticationActions.AuthenticateSendEmailVerificationCodeRejected(
                  {
                    errors,
                  }
                )
              );
            })
          )
      )
    )
  );

  setReturnURl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LgAuthenticationActions.AuthenticateSetReturnURlPending),
      mergeMap((action) =>
        this.signinApiService
          .registerReturnUrl({
            returnUrl: action.payload,
          })
          .pipe(
            map(() =>
              LgAuthenticationActions.AuthenticateSetReturnURlFulfilled()
            ),
            catchError((err: ApiException) => {
              const errors: LgErrorMessage[] = [];

              errors.push({
                message: 'Unhandled error',
                statusCode: err.status,
                result: err.result.result,
              });

              return of(
                LgAuthenticationActions.AuthenticateSetReturnURlRejected({
                  errors,
                })
              );
            })
          )
      )
    )
  );

  getForbiddenReason$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LgAuthenticationActions.AuthenticateGetForbiddenReasonPending),
      mergeMap(() =>
        this.signinApiService
          .getForbiddenReason(ForbiddenReason.OeiIdentityNotRelatedToFgUser)
          .pipe(
            map((res) =>
              LgAuthenticationActions.AuthenticateGetForbiddenReasonFulfilled({
                forbiddenReason: res,
              })
            ),
            catchError((err: ApiException) => {
              const errors: LgErrorMessage[] = [];

              errors.push({
                message: 'Unhandled error',
                statusCode: err.status,
                result: err.result.result,
              });

              return of(
                LgAuthenticationActions.AuthenticateGetForbiddenReasonRejected({
                  errors,
                })
              );
            })
          )
      )
    )
  );
}

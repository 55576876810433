import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as LgAuthReducer from './auth.reducer';
import { LgAuthenticationState } from './auth.state';

export const selectAuthentication = createFeatureSelector<LgAuthenticationState>(
  LgAuthReducer.authenticationFeatureKey,
);

export const selectAuthenticationFlow = createSelector(
  selectAuthentication,
  (state: LgAuthenticationState) => state.authenticationFlow,
);

export const selectActiveAuthenticationStep = createSelector(
  selectAuthentication,
  (state: LgAuthenticationState) => state.authenticationFlow.activeAuthenticationStep,
);

export const selectAuthenticationIsLoading = createSelector(
  selectAuthentication,
  (state: LgAuthenticationState) => state.isLoading,
);

export const selectAuthenticationResult = createSelector(
  selectAuthentication,
  (state: LgAuthenticationState) => state.result,
);

export const selectAuthenticationForbiddenResult = createSelector(
  selectAuthentication,
  (state: LgAuthenticationState) => state.forbiddenReason,
);

export const selectAuthenticationContext = createSelector(
  selectAuthentication,
  (state: LgAuthenticationState) => state.context,
);

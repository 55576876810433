import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { ApiException, UserApiService } from '@api';

import { AppState } from '../app.state';
import * as LgAuthenticationActions from '../auth/auth.actions';
import { EAuthenticationStep } from '../models/authentication-step.enum';
import { LgErrorMessage } from '../models/error-message.model';
import * as LgUserActions from './user.actions';
import * as LgUserSelectors from './user.selector';

@Injectable()
export class LgUserEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly userApiService: UserApiService,
    private readonly store: Store<AppState>,
  ) {}

  loadUserInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LgUserActions.LoadUserInfoPending),
      mergeMap((action) =>
        this.userApiService.getUserInfo(action.payload).pipe(
          map((res) =>
            LgUserActions.LoadUserInfoFulfilled({
              identityType: res.identityType,
            })
          ),

          catchError((err: ApiException) => {
            const errors: LgErrorMessage[] = [];

            errors.push({
              message: 'Unhandled error',
              statusCode: err.status,
            });

            return of(LgUserActions.LoadUserInfoRejected({ errors }));
          }),
        ),
      ),
    ),
  );

  loadEnvironments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LgUserActions.LoadEnvironmentsPending),
      withLatestFrom(this.store.select(LgUserSelectors.selectEmailAddress)),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      mergeMap(([_, emailAddress]) =>
        this.userApiService
          .getFgEnvironments({
            userName: emailAddress as string,
          })
          .pipe(
            map((res) =>
              LgUserActions.LoadEnvironmentsFulfilled({
                environments: res.environments as string[],
                preferredEnvironment: res.preferredEnvironment as string,
                notification: res.notification as string,
              }),
            ),
            catchError((err: ApiException) => {
              const errors: LgErrorMessage[] = [];

              errors.push({
                message: 'Unhandled error',
                statusCode: err.status,
              });

              return of(LgUserActions.LoadEnvironmentsRejected({ errors }));
            }),
          ),
      ),
    ),
  );

  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LgUserActions.ResetPasswordPending),
      mergeMap((action) =>
        this.userApiService.forgotPassword(action.payload).pipe(
          map(() => LgUserActions.ResetPasswordFulfilled()),
          map(() =>
            LgAuthenticationActions.SetAuthenticationFlow({
              activeAuthenticationStep:
                EAuthenticationStep.ResetPasswordSuccess,
              progress: 'nextStep',
            }),
          ),

          catchError((err: ApiException) => {
            const errors: LgErrorMessage[] = [];

            errors.push({
              message: 'Unhandled error',
              statusCode: err.status,
            });

            return of(LgUserActions.ResetPasswordRejected({ errors }));
          }),
        ),
      ),
    ),
  );
}

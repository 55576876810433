import { Component, Input } from '@angular/core';

export type Appearance = 'primary' | 'secondary' | 'tertiary';
@Component({
  selector: 'lg-link',
  templateUrl: './link.component.html',
})
export class LgLinkComponent {
  @Input() appearance: Appearance = 'primary';

  @Input() fontWeight: 'normal' | 'bold' = 'normal';

  @Input() icon?: string;

  @Input() iconSide: 'appended' | 'preappended' = 'preappended';

  @Input() size: 'small' | 'medium' = 'medium';

  @Input() href?: string;

  @Input() target?: '_blank' | '_self' | '_parent' | '_top';
}

<p
  [ngClass]="{
    'text-sm': size === 'medium',
    'text-xs': size === 'small',
    'font-bold': fontStyle === 'bold'
  }"
  class="leading-relaxed"
>
  <ng-content></ng-content>
</p>

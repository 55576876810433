import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { UserEnvironmentsResponseModel } from '@api';

import * as LgConsentActions from './consent.actions';
import { LgConsentState } from './consent.state';

export const consentFeatureKey = 'consent';

export const adapter: EntityAdapter<UserEnvironmentsResponseModel> =
  createEntityAdapter<UserEnvironmentsResponseModel>();

export const initialState: LgConsentState = {
  result: null,
  redirectUrl: null,
  isLoading: false,
  errors: null,
};

export const reducer = createReducer(
  initialState,
  on(LgConsentActions.GetConsentInfoPending, (state) => ({
    ...state,
    isLoading: true,
    errors: null,
  })),
  on(LgConsentActions.GetConsentInfoFulfilled, (state, { result }) => ({
    ...state,
    result,
    isLoading: false,
    errors: null,
  })),
  on(LgConsentActions.GetConsentInfoRejected, (state, { errors }) => ({
    ...state,
    result: null,
    isLoading: false,
    errors,
  })),
  on(LgConsentActions.SaveConsentInfoPending, (state) => ({
    ...state,
    isLoading: true,
    errors: null,
  })),
  on(LgConsentActions.SaveConsentInfoFulfilled, (state, { redirectUrl }) => ({
    ...state,
    redirectUrl,
    isLoading: false,
    errors: null,
  })),
  on(LgConsentActions.SaveConsentInfoRejected, (state, { errors }) => ({
    ...state,
    redirectUrl: null,
    isLoading: false,
    errors,
  })),
);

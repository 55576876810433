import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { LgUserEffects } from './user.effects';
import * as LgUserReducer from './user.reducer';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(LgUserReducer.userFeatureKey, LgUserReducer.reducer),
    EffectsModule.forFeature([LgUserEffects]),
  ],
  providers: [LgUserEffects],
})
export class LgUserFeatureSliceModule {}

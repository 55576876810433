import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LgIconComponent } from './icon.component';

@NgModule({
  imports: [CommonModule],
  declarations: [LgIconComponent],
  exports: [LgIconComponent],
})
export class LgIconModule {}

import { createAction, props } from '@ngrx/store';

import { OeiMigrationEolDetails, OeiMigrationStepResponse } from '@api';

import { LgErrorMessage } from '../models/error-message.model';

export const MigrateResetState = createAction('[Migrate] Reset State');

export const MigrateCheckMigrationPending = createAction(
  '[Migrate] Check Migration Pending'
);

export const MigrateCheckMigrationFulfilled = createAction(
  '[Migrate] Check Migration Fulfilled',
  props<{ result: OeiMigrationStepResponse }>(),
);

export const MigrateCheckMigrationRejected = createAction(
  '[Migrate] Check Migration Rejected',
  props<{ errors: LgErrorMessage[] }>(),
);

export const MigrateCompleteMigrationPending = createAction(
  '[Migrate] Complete Migration Pending'
);

export const MigrateCompleteMigrationFulfilled = createAction(
  '[Migrate] Complete Migration Fulfilled',
  props<{ result: OeiMigrationStepResponse }>(),
);

export const MigrateCompleteMigrationRejected = createAction(
  '[Migrate] Complete Migration Rejected',
  props<{ errors: LgErrorMessage[] }>(),
);

export const MigrateInitialSignInOeiUserPending = createAction(
  '[Migrate] Migrate Initial Sign In OEI User Pending',
);

export const MigrateInitialSignInOeiUserFulfilled = createAction(
  '[Migrate] Migrate Initial Sign In OEI User Fulfilled',
  props<{ result: OeiMigrationStepResponse }>(),
);

export const MigrateInitialSignInOeiUserRejected = createAction(
  '[Migrate] Migrate Initial Sign In OEI User Rejected',
  props<{ errors: LgErrorMessage[] }>(),
);

export const MigrateInitialSendEmailTokenPending = createAction(
  '[Migrate] Initial Email Token Pending',
);

export const MigrateInitialSendEmailTokenFulfilled = createAction(
  '[Migrate] Initial Send Email Token Fulfilled',
  props<{ result: OeiMigrationStepResponse }>(),
);

export const MigrateInitialSendEmailTokenRejected = createAction(
  '[Migrate] Initial Send Email Token Rejected',
  props<{ errors: LgErrorMessage[] }>(),
);

export const MigrateInitialVerifyEmailTokenPending = createAction(
  '[Migrate] Initial Verify Email Token Pending',
  props<{ payload: string }>(),
);

export const MigrateInitialVerifyEmailTokenFulfilled = createAction(
  '[Migrate] Initial Verify  Email Token Fulfilled',
  props<{ result: OeiMigrationStepResponse }>(),
);

export const MigrateInitialVerifyEmailTokenRejected = createAction(
  '[Migrate] Initial Verify Email Token Rejected',
  props<{ errors: LgErrorMessage[] }>(),
);

export const MigrateUserSendEmailTokenPending = createAction(
  '[Migrate] User Email Token Pending'
);

export const MigrateUserSendEmailTokenFulfilled = createAction(
  '[Migrate] User Send Email Token Fulfilled',
  props<{ result: OeiMigrationStepResponse }>(),
);

export const MigrateUserSendEmailTokenRejected = createAction(
  '[Migrate] User Send Email Token Rejected',
  props<{ errors: LgErrorMessage[] }>(),
);

export const MigrateUserVerifyEmailTokenPending = createAction(
  '[Migrate] User Verify Email Token Pending',
  props<{ payload: string }>(),
);

export const MigrateUserVerifyEmailTokenFulfilled = createAction(
  '[Migrate] User Verify  Email Token Fulfilled',
  props<{ result: OeiMigrationStepResponse }>(),
);

export const MigrateUserVerifyEmailTokenRejected = createAction(
  '[Migrate] User Verify Email Token Rejected',
  props<{ errors: LgErrorMessage[] }>(),
);

export const MigrateUserLinkUserPending = createAction(
  '[Migrate] Migrate User Link User Pending'
);

export const MigrateUserLinkUserFulfilled = createAction(
  '[Migrate] Migrate User Link User Fulfilled',
  props<{ result: OeiMigrationStepResponse }>(),
);

export const MigrateUserLinkUserRejected = createAction(
  '[Migrate] Migrate User Link User Rejected',
  props<{ errors: LgErrorMessage[] }>(),
);

export const MigrateInitialMigrationNewEolAccountPending = createAction(
  '[Migrate] Migrate Initial Migration New Eol Account Pending',
);

export const MigrateInitialMigrationNewEolAccountFulfilled = createAction(
  '[Migrate] Migrate Initial Migration New Eol Account Fulfilled',
  props<{ result: OeiMigrationStepResponse }>(),
);

export const MigrateInitialMigrationNewEolAccountRejected = createAction(
  '[Migrate] Migrate Initial Migration New Eol Account Rejected',
  props<{ errors: LgErrorMessage[] }>(),
);

export const MigrateInitialSignInExistingEolAccountPending = createAction(
  '[Migrate] Migrate Initial Sign In Existing Eol Account Pending',
);

export const MigrateInitialSignInExistingEolAccountFulfilled = createAction(
  '[Migrate] Migrate Initial Sign In Existing Eol Account Fulfilled',
  props<{ result: OeiMigrationStepResponse }>(),
);

export const MigrateInitialSignInExistingEolAccountRejected = createAction(
  '[Migrate] Migrate Initial Sign In Existing Eol Account Rejected',
  props<{ errors: LgErrorMessage[] }>(),
);

export const MigrateInitialEolDetailsPending = createAction(
  '[Migrate] Migrate Initial Eol Details Pending',
);

export const MigrateInitialEolDetailsFulfilled = createAction(
  '[Migrate] Migrate Initial Eol Details Fulfilled',
  props<{ eolDetails: OeiMigrationEolDetails }>(),
);

export const MigrateInitialEolDetailsRejected = createAction(
  '[Migrate] Migrate Initial Eol Details Rejected',
  props<{ errors: LgErrorMessage[] }>(),
);

export const MigrateInitialExistingEolAccountPending = createAction(
  '[Migrate] Migrate Initial Existing Eol Account Pending',
);

export const MigrateInitialExistingEolAccountFulfilled = createAction(
  '[Migrate] Migrate Initial Existing Eol Account Fulfilled',
  props<{ result: OeiMigrationStepResponse }>(),
);

export const MigrateInitialExistingEolAccountRejected = createAction(
  '[Migrate] Migrate Initial Existing Eol Account Rejected',
  props<{ errors: LgErrorMessage[] }>(),
);

export const MigrateInitialGetCurrentStatePending = createAction(
  '[Migrate] Migrate Initial Get Current State Pending',
);

export const MigrateInitialGetCurrentStateFulfilled = createAction(
  '[Migrate] Migrate Initial Get Current State Fulfilled',
  props<{ result: OeiMigrationStepResponse }>(),
);

export const MigrateInitialGetCurrentStateRejected = createAction(
  '[Migrate] Migrate Initial Get Current State Rejected',
  props<{ errors: LgErrorMessage[] }>(),
);

<i
  *ngIf="name"
  [class]="'block text-[1.25rem] bi bi-' + name"
  [ngClass]="{
    'text-blue-700': appearance === 'info',
    'text-green-700': appearance === 'success',
    'text-yellow-700': appearance === 'warning',
    'text-red-700': appearance === 'danger'
  }"
></i>

import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { LgConsentEffects } from './consent.effects';
import * as LgConsentReducer from './consent.reducer';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(LgConsentReducer.consentFeatureKey, LgConsentReducer.reducer),
    EffectsModule.forFeature([LgConsentEffects]),
  ],
  providers: [LgConsentEffects],
})
export class LgConsentFeatureSliceModule {}

<a
  class="hover:cursor-pointer hover:underline"
  [ngClass]="{
    'text-brand-primary-blue-base': appearance === 'primary',
    'text-black': appearance === 'secondary',
    'text-gray-500': appearance === 'tertiary',
    'font-bold': fontWeight === 'bold',
    'text-xs': size === 'small',
    'text-sm': size === 'medium'
  }"
  [attr.href]="href"
  [attr.target]="target"
>
  <lg-icon
    *ngIf="icon && iconSide === 'preappended'"
    class="relative top-[4px] mr-1.5 inline-block"
    [name]="icon"
  ></lg-icon>
  <ng-content></ng-content>
  <lg-icon
    *ngIf="icon && iconSide === 'appended'"
    class="relative top-[4px] ml-1.5 inline-block"
    [name]="icon"
  ></lg-icon>
</a>
